import { Caution as CautionIcon, Eye as EyeIcon } from '@air/next-icons';
import { BannerProps } from '@air/primitive-banner';
import { Box, BoxProps, ColorName, SVGComponent } from '@air/zephyr';
import classNames from 'classnames';
import { isValidElement, ReactNode } from 'react';

import { MODAL_BANNER_CONTAINER } from '~/constants/testIDs';

type BannerFlavor = Required<BannerProps>['appearance'];

export interface ModalBannerProps extends Pick<BoxProps, 'tx'> {
  flavor?: BannerFlavor;
  showSubtitleIcon?: boolean;
  subtitle?: string | ReactNode;
  title?: string;
}

const FLAVOR_STYLES: {
  [key in BannerFlavor]: {
    color: ColorName;
    bg: ColorName;
    iconName?: SVGComponent;
  };
} = {
  warning: { color: 'canary900', bg: 'canary050', iconName: EyeIcon },
  danger: { color: 'flamingo800', bg: 'flamingo100', iconName: CautionIcon },
  info: { color: 'jay900', bg: 'jay050', iconName: EyeIcon },
  success: { color: 'jay900', bg: 'peacock200', iconName: EyeIcon },
  quiet: { color: 'pigeon700', bg: 'pigeon025' },
};

/**
 * @deprecated Use `@air/primitive-banner` instead.
 */
export const ModalBanner = ({ flavor = 'success', title, subtitle, tx, showSubtitleIcon }: ModalBannerProps) => {
  const { color, bg, iconName: Icon } = FLAVOR_STYLES[flavor];

  return (
    <Box
      tx={{
        backgroundColor: bg,
        borderRadius: 4,
        color: color,
        display: 'flex',
        p: 8,
        ...tx,
      }}
      data-testid={`${MODAL_BANNER_CONTAINER}-${flavor}`}
    >
      <div className="flex h-full w-[400px] flex-col flex-wrap justify-center">
        {!!title && (
          <div
            className="ml-2 w-full truncate text-14 font-semibold"
            style={{
              color: color,
            }}
          >
            {title}
          </div>
        )}
        <div className="flex w-full pl-2">
          {showSubtitleIcon && !!Icon && <Icon className="h-4 w-4" />}
          {isValidElement(subtitle) ? (
            subtitle
          ) : (
            <div className={classNames('text-14 text-grey-11', !title ? 'py-2' : undefined)} style={{ color }}>
              {subtitle}
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};
