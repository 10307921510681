import { tailwindMerge } from '@air/tailwind-variants';
import { differenceInDays, format, startOfToday } from 'date-fns';
import pluralize from 'pluralize';
import { useMemo } from 'react';

import { parseAirDateToISO } from '~/utils/DateUtils';

interface SharePrivateContentExpirationDateDisplayProps {
  expirationDate: string;
  goToAdvancedSettings: () => void;
}

export const SharePrivateContentExpirationDateDisplay = ({
  expirationDate,
  goToAdvancedSettings,
}: SharePrivateContentExpirationDateDisplayProps) => {
  const date = parseAirDateToISO(expirationDate);
  const difference = differenceInDays(date, startOfToday());
  const text = useMemo(() => {
    if (difference < 0) return `Link expired on ${format(date, 'MMM d, yyyy')}.`;
    return `Link expires ${difference === 0 ? 'today.' : `in ${pluralize('day', difference, true)}.`}`;
  }, [difference, date]);

  const textColor = difference < 1 ? 'text-flamingo-600' : difference === 1 ? 'text-jay-600' : 'text-pigeon-400';
  const hoverColor = difference < 1 ? 'hover:text-flamingo-700' : difference === 1 ? 'hover:text-jay-700' : '';
  return (
    <p className={tailwindMerge('text-14', textColor, hoverColor)} role="alert">
      {text}
      <button
        onClick={goToAdvancedSettings}
        className={tailwindMerge(
          'ml-1 font-semibold',
          textColor,
          difference > 1 ? 'hover:text-pigeon-700' : hoverColor,
        )}
      >
        Manage expiration date &#8594;
      </button>
    </p>
  );
};
