import FileExtensionPreview, {
  FileExtensionPreviewColor,
  FileExtensionPreviewSize,
} from '~/components/FileExtensionPreview';
import { ClipIconSize } from '~/types/clip-icon-size';
import { WebClipType } from '~/utils/ClipUtils';

import { ImageContainer } from './ui';

export const NO_PREVIEW_AVAILABLE = 'NO PREVIEW AVAILABLE';

export type ClipStatusInfoLocationContext = 'default' | 'mediaPlayer';

export interface NoPreviewImageProps {
  /** Denotes whether the clip is in a processing state */
  processing?: boolean;
  /** Web Clip Type that categorizes clips into more specific types e.g PDF, Document, File */
  type?: WebClipType;
  /** File extension used to differentiate file icons and colors*/
  ext: string;
  /** Custom text to be displayed below the icon in the generated preview */
  customText?: string;
  /** Denotes whether the text below an icon should be displayed */
  hideText?: boolean;
  /** borderRadius of the container of the generated preeview */
  borderRadius?: number;
  /** Color passed from parent to render FileExtensionPreview in appropriate style */
  color?: FileExtensionPreviewColor;
  /** Size passed from parent to render FileExtensionPreview in appropriate size */
  size?: FileExtensionPreviewSize;
  context?: ClipStatusInfoLocationContext;
  iconSize?: ClipIconSize;
}

/** Visual representation of a Clip without a preview image.
 * Graceefully Handles the transition between clips in their processing state to their final previews.
 * */
export const NoPreviewImage = ({
  customText,
  processing,
  ext,
  hideText,
  borderRadius,
  color,
  size,
  ...rest
}: NoPreviewImageProps) => {
  const processingText = processing ? 'Processing' : NO_PREVIEW_AVAILABLE;
  return (
    <ImageContainer
      processing={processing}
      borderRadius={size === 'medium' ? 6 : borderRadius}
      color={color}
      ext={ext}
      {...rest}
    >
      <FileExtensionPreview
        ext={ext}
        text={hideText ? '' : customText || processingText}
        color={color}
        size={size}
        tx={{ justifyContent: 'center', flexGrow: 1 }}
      />
    </ImageContainer>
  );
};
