import { AirActionTrackingLocation } from '@air/analytics';
import { Board } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { TransactionModal } from '@air/zephyr';
import { noop } from 'lodash';
import pluralize from 'pluralize';
import { useState } from 'react';

import { DUPLICATE_BOARDS_MODAL } from '~/constants/testIDs';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { useCreateDuplicateBoardTask } from './hooks/useCreateDuplicateBoardTask';

export interface DuplicateBoardModalProps {
  boards: Board[];
  trackLocation: AirActionTrackingLocation;
  onDuplicate?: () => void;
}

export const DuplicateBoardModal = ({
  onClose,
  boards = [],
  trackLocation,
  onDuplicate = noop,
}: AirModalProps<DuplicateBoardModalProps>) => {
  const [isDuplicating, setIsDuplicating] = useState(false);
  const { showToast } = useToasts();
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { currentWorkspace } = useCurrentWorkspace();
  const { createDuplicateBoardTask } = useCreateDuplicateBoardTask();
  const workspaceId = currentWorkspace?.id;

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const duplicate = async () => {
    const targetId = boards[0].parentId;

    if (!workspaceId) {
      throw new Error('No workspaceId found');
    }

    try {
      setIsDuplicating(true);
      await createDuplicateBoardTask({
        prefix: 'Copy of ',
        sourceBoardIds: boards.map(({ id }) => id),
        targetBoardId: targetId ?? undefined,
        sourceBoardName: boards[0].title,
        trackLocation,
      });

      onDuplicate();
      setIsDuplicating(false);
      onClose();
    } catch (error) {
      reportErrorToBugsnag({
        error,
        context: 'Failed to duplicate boards',
        metadata: {
          data: {
            boards: boards.map(({ id }) => id).join(', '),
            parentId: boards[0].parentId,
          },
        },
      });

      setIsDuplicating(false);

      showToast('There was an error while trying to duplicate this board. Please try again later.');
    }
  };

  return (
    <TransactionModal
      primaryCTA={{
        children: 'Duplicate',
        onClick: duplicate,
        isLoading: isDuplicating,
      }}
      secondaryCTA={{
        children: 'Cancel',
        onClick: onClose,
        size: 'medium',
      }}
      data-testid={DUPLICATE_BOARDS_MODAL}
      modalLabel={`Duplicate ${pluralize('board', boards.length)}`}
      modalDescription="Duplicating a board will make an exact copy of the board, including its assets, files, and sub-boards."
      onDismiss={onClose}
    />
  );
};
