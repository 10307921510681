import { WorkspaceMemberType } from '@air/api/types';
import { TriangleDown } from '@air/next-icons';
import { formatFullName, formatInitials } from '@air/utilities';
import { Box, Text } from '@air/zephyr';
import { rgba } from 'polished';
import React, { memo, RefObject } from 'react';
import { useTheme } from 'styled-components';

import { Avatar } from '~/components/Avatar';
import { MentionsData } from '~/components/Discussions/DiscussionForm';
import { PopperOptions, usePopperContainer } from '~/hooks/usePopperContainer';

const popperOptions: PopperOptions = {
  placement: 'top',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -10],
      },
    },
  ],
};

export interface UserMentionTooltipProps {
  user: MentionsData;
  containerRef: RefObject<HTMLElement>;
}

export const UserMentionTooltip = memo(
  ({ containerRef, user: { firstName, lastName, avatar, id, type: userType, roles } }: UserMentionTooltipProps) => {
    const fullName = formatFullName(firstName, lastName);
    const initials = formatInitials(firstName, lastName);
    const theme = useTheme();

    const { PopperContainer } = usePopperContainer({ containerRef, usePortal: true, options: popperOptions });

    const roleName =
      userType === WorkspaceMemberType.member ? roles.member?.displayName : roles?.guest?.[0]?.displayName;

    return (
      <PopperContainer>
        <Box tx={{ pointerEvents: 'none', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Box
            tx={{
              bg: 'white',
              display: 'flex',
              p: 8,
              borderRadius: 2,
              alignItems: 'center',
              boxShadow: `0px 2px 8px ${rgba(theme.colors.black, 0.2)}, 0px 1px 3px ${rgba(
                theme.colors.black,
                0.2,
              )}, 0px 0px 2px ${rgba(theme.colors.black, 0.2)}`,
            }}
          >
            <Avatar alt={fullName} size={24} src={avatar} text={initials} type="imgix" colorSeed={id} />
            <Text variant="text-ui-14" tx={{ color: 'pigeon600', fontWeight: 'medium', ml: 8 }}>
              {fullName}
            </Text>
            {!!roleName && (
              <>
                <div className="mx-1 text-14 font-medium text-grey-10">・</div>
                <div className="text-14 text-grey-10">{roleName}</div>
              </>
            )}
          </Box>
          <Box
            as={TriangleDown}
            tx={{
              width: 32,
              color: 'white',
              mt: -14,
              filter: 'drop-shadow(0px 3px 1px rgba(0, 0, 0, .2))',
            }}
          />
        </Box>
      </PopperContainer>
    );
  },
);

UserMentionTooltip.displayName = 'UserMentionTooltip';
