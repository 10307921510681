import { Board } from '@air/api/types';
import { TreeItem, TreeItemButton } from '@air/component-tree';
import { Check } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Tooltip } from '@air/primitive-tooltip';
import { tailwindMerge } from '@air/tailwind-variants';
import { Box } from '@air/zephyr';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useLibraryRootBoards } from '~/components/LibraryBeta/hooks/queries/useLibraryRootBoards';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

import { BoardListItemProps, BoardSearchListItem } from './BoardSearchListItem';

type Library = Required<Board>['library'];

export interface LibraryListItemProps
  extends Pick<
    BoardListItemProps,
    'getIsBoardDisabled' | 'initialBoardId' | 'isBoardSelected' | 'onBoardSelectChange' | 'shouldShowSubBoards'
  > {
  getIsLibraryDisabled?: (library: Library) => { isDisabled: true; message: string } | undefined;
  isLibrarySelected?: (library: Library) => boolean;
  library: Library;
  libraryBoards?: Board[];
  onSelectLibrary?: (library: Library) => void;
  shouldExpandLibraryInsteadOfSelect?: boolean;
  shouldShowLibraryBoards?: (libraryId: Library['id']) => boolean;
}

export const LibraryListItem = memo(
  ({
    getIsLibraryDisabled,
    getIsBoardDisabled,
    initialBoardId,
    isBoardSelected,
    isLibrarySelected,
    library,
    libraryBoards,
    onBoardSelectChange: onBoardClick,
    onSelectLibrary,
    shouldExpandLibraryInsteadOfSelect,
    shouldShowLibraryBoards,
    shouldShowSubBoards,
  }: LibraryListItemProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const { currentWorkspace } = useCurrentWorkspace();
    const toggleExpanded = useCallback(() => {
      setIsExpanded((prevState) => !prevState);
    }, [setIsExpanded]);

    const isSelected = !!isLibrarySelected?.(library);
    const isDisabled = getIsLibraryDisabled?.(library);

    const onClickTitle = useCallback(() => {
      if (shouldExpandLibraryInsteadOfSelect) {
        toggleExpanded();
      } else {
        onSelectLibrary?.(library);
      }
    }, [onSelectLibrary, library, shouldExpandLibraryInsteadOfSelect, toggleExpanded]);

    useEffect(() => {
      if (shouldShowLibraryBoards?.(library.id)) {
        setIsExpanded(true);
      }
    }, [shouldShowLibraryBoards, library.id]);

    useEffect(() => {
      if (isSelected && containerRef.current) {
        containerRef.current.scrollIntoView();
      }
    }, [isSelected, library]);

    // this will be replaced with a proper api call when we have the endpoint
    const {
      data: boards = [],
      isLoadingMore,
      hasMore,
      loadNextPage,
    } = useLibraryRootBoards(isExpanded && !libraryBoards ? currentWorkspace?.id : undefined, library.id);

    useFetchObjectsPermissions({
      objects: {
        boardIds: boards.map((board) => board.id),
      },
    });

    const treeElement = (
      <TreeItemButton
        className={tailwindMerge(!!isDisabled && 'pointer-events-none', !!isDisabled && 'opacity-60')}
        onClick={onClickTitle}
      >
        {library.title}
      </TreeItemButton>
    );

    return (
      <Box
        ref={containerRef}
        tx={{
          '& .library-item-chevron': {
            opacity: 0,
          },
          [':hover']: {
            '& .library-item-chevron': {
              opacity: 1,
            },
          },
        }}
      >
        <TreeItem
          data-title={library.title}
          className={isSelected ? 'bg-macaw-20' : 'hover:bg-pigeon-50'}
          suffix={isSelected && <Check className="flex h-4 w-4 shrink-0 items-center text-jay-600" />}
          open={isExpanded}
          onOpenChange={toggleExpanded}
        >
          {!!isDisabled ? (
            <Tooltip label={isDisabled.message} side="left" sideOffset={0}>
              <div>{treeElement}</div>
            </Tooltip>
          ) : (
            treeElement
          )}
        </TreeItem>
        {isExpanded && (
          <div className="ml-4">
            {(libraryBoards || boards).map((board) => {
              return (
                <Box key={board.id}>
                  <BoardSearchListItem
                    shouldShowSubBoards={shouldShowSubBoards}
                    isBoardSelected={isBoardSelected}
                    onBoardSelectChange={onBoardClick}
                    board={board}
                    getIsBoardDisabled={getIsBoardDisabled}
                    initialBoardId={initialBoardId}
                  />
                </Box>
              );
            })}
            {!isLoadingMore && hasMore && (
              <Button onClick={() => loadNextPage()} className="ml-4 px-4" size="small" appearance="ghost" color="grey">
                Show more
              </Button>
            )}
          </div>
        )}
      </Box>
    );
  },
);

LibraryListItem.displayName = 'LibraryListItem';
