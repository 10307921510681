import { Slack as SlackIcon } from '@air/next-icons';
import classNames from 'classnames';
import { memo } from 'react';

import { displaySlackChannelName } from '../utils/displaySlackChannelName';

interface ConnectedSlackChannelProps {
  channelName: string;
}

export const ConnectedSlackChannel = memo(({ channelName }: ConnectedSlackChannelProps) => {
  return (
    <div
      className={classNames(
        'p-inline-3 flex h-12 flex-col justify-center border-0 bg-pigeon-50 text-pigeon-600',
        'connected-slack-channel-container',
      )}
    >
      <div className="flex items-center">
        <SlackIcon className="mr-1 mt-0.5 h-4 w-4" />
        <div className="text-14 font-normal text-inherit">Connected to Slack</div>
      </div>
      <div className="ml-5 text-12 font-normal text-pigeon-500">{displaySlackChannelName(channelName)}</div>
    </div>
  );
});

ConnectedSlackChannel.displayName = 'ConnectedSlackChannel';
