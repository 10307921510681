import { Box, SpaceName } from '@air/zephyr';
import { Tab, TabList, TabPanel, TabPanels, TabsProps } from '@reach/tabs';
import { Children, CSSProperties, ReactNode } from 'react';

import { AirTabsContainer } from './ui';

interface AirTabsProps extends TabsProps {
  tabsTitles: string[];
  children: ReactNode;
  isPageHeader?: boolean;
  style?: CSSProperties;
}

/**
 * This component is used to display its children in tabs
 * It does not display tab title if there is only one tab
 */
const AirTabs = ({ tabsTitles, children, isPageHeader = false, style, ...props }: AirTabsProps) => {
  let width: string | number = 'auto';
  let mx: SpaceName = 0;

  if (isPageHeader) {
    width = 576;
    mx = 'auto';
  }

  return (
    <AirTabsContainer {...props} style={{ marginBottom: isPageHeader ? 0 : 24, ...style }}>
      {tabsTitles.length > 1 && (
        <TabList>
          <Box tx={{ width, mx }}>
            {tabsTitles.map((tab, index) => (
              <Tab data-testid={`TAB-${tab}`} key={index}>
                {tab}
              </Tab>
            ))}
          </Box>
        </TabList>
      )}

      <TabPanels>
        {Children.map(children, (child) => (
          <TabPanel>{child}</TabPanel>
        ))}
      </TabPanels>
    </AirTabsContainer>
  );
};

export default AirTabs;
