import { type Board } from '@air/api/types';
import { Box, type BoxProps } from '@air/zephyr';
import { type ReactNode } from 'react';
import { type BackgroundProps } from 'react-imgix';

import { ImgixImage, type ImgixImageParams } from '~/components/ImgixImage';
import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';
import { BOARD_CARD_THUMBNAIL } from '~/constants/testIDs';

export interface BoardThumbnailProps extends Pick<BoxProps, 'tx'> {
  board: Pick<Board, 'thumbnails' | 'title'>;
  children?: ReactNode;
  /** This is the img "sizes" attribute that should be used to determine the board thumbnail sizes */
  sizes: ImgixImageParams['sizes'];

  width: string | number | (string | number)[];
  height: string | number | (string | number)[];
  borderRadius?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  objectFit?: 'contain' | 'cover' | 'fill' | 'scale-down' | 'none' | 'initial' | 'inherit';
  imgixParams?: BackgroundProps['imgixParams'];
}

/**
 * This is used to display board's thumbnail (only image)
 */
const BoardThumbnail = ({
  board: { thumbnails, title },
  children,
  sizes,
  width,
  height,
  borderRadius = 4,
  onClick,
  imgixParams,
  objectFit = 'contain',
  tx,
}: BoardThumbnailProps) => {
  const thumbnail = thumbnails && thumbnails.length ? thumbnails[0] : null;
  const isBase64Image = thumbnail && thumbnail.startsWith('data:image');
  const fallbackUrl = thumbnail && getCloudFrontUrlFromImgixUrl(thumbnail);

  return (
    <Box
      data-testid={`${BOARD_CARD_THUMBNAIL}-${title}`}
      tx={{
        position: 'relative',
        width,
        height,
        minWidth: width,
        borderRadius,
        overflow: 'hidden',

        '> img': {
          position: 'relative',
          objectFit,
          height: '100%',
          width: '100%',
        },
        ...(tx as any),
      }}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      onClick={onClick}
    >
      {isBase64Image ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img src={thumbnail} alt={title} />
      ) : fallbackUrl && thumbnail ? (
        <ImgixImage
          fallbackUrl={fallbackUrl}
          sizes={sizes}
          alt={title}
          src={thumbnail}
          imgixParams={{
            w: 400,
            h: 400,
            auto: 'compress',
            fit: 'contain',
            ...imgixParams,
          }}
          width={400}
          height={400}
          disableSrcSet
        />
      ) : (
        <div className="h-full w-full bg-grey-3" />
      )}
      {children}
    </Box>
  );
};

export default BoardThumbnail;
