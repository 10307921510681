import { Button, ButtonProps } from '@air/zephyr';

export const ToastLink = ({ tx, ...props }: ButtonProps) => (
  <Button
    variant="button-unstyled"
    //@ts-ignore
    tx={{
      display: 'inline-block',
      maxWidth: [200, 200, 328],
      color: 'jay200',
      fontWeight: 'medium',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 1.5, // to prevent cut bottom part of y,g,j etc.
      fontSize: 16,

      ':hover': {
        backgroundColor: 'transparent',
        color: 'jay200',
      },
      ...tx,
    }}
    {...props}
  />
);
