import { Box, Error, FieldVariantName, LabelPrimitive, TXProp } from '@air/zephyr';
import VisuallyHidden from '@reach/visually-hidden';
import classNames from 'classnames';
import { useField } from 'formik';
import { memo, useCallback, useMemo } from 'react';

import { SelectChipOptionContainer } from '../Select/components/SelectChipOptionContainer';
import {
  ChipRenderer,
  ListItemRenderer,
  OnSelectionChangeArgs,
  orderedSortedFilterFunc,
  Select,
  SelectProps,
} from '../Select/Select';
import { DefaultChipType } from '../Select/shared/types';
import { SpacingStyles } from '../types';

export interface FormikSingleSelectProps
  extends Pick<
    SelectProps,
    | 'disabled'
    | 'isLoading'
    | 'isSearchable'
    | 'onOptionsClose'
    | 'onOptionsOpen'
    | 'options'
    | 'placeholder'
    | 'variant'
  > {
  onChange?: () => void;
  'data-testid'?: string;
  /**
   * An input must always have a label, but design may want it visually hidden.
   */
  isLabelHidden?: boolean;
  /**
   * The content of the label. No need for * when required - it's added automatically.
   */
  label: string;
  /**
   * tx styles for the label
   */
  labelStyles?: TXProp;
  /**
   * Should match the relevant key name inside the Formik schema.
   */
  name: string;
  /**
   * Only adds an asterisk to label
   */
  required?: boolean;
  spacingStyles?: SpacingStyles;
  /**
   * A value to set as the max height for the dropdown.
   */
  maxDropdownHeight?: number;
}

export const FormikSingleSelect = memo<FormikSingleSelectProps>(
  ({
    'data-testid': dataTestId,
    disabled = false,
    isLabelHidden = false,
    isLoading,
    isSearchable = true,
    label,
    labelStyles,
    name,
    onChange,
    onOptionsClose,
    onOptionsOpen,
    options,
    placeholder,
    required,
    variant = 'field-input-smol',
    spacingStyles,
    maxDropdownHeight = 300,
  }: FormikSingleSelectProps) => {
    const [field, meta, helpers] = useField<string>(name);
    const errorID = `${name}_error`;
    const hasError = meta.touched && !!meta.error;
    const isChonky = variant === 'field-input-chonky';
    const largeTextVariant = `${variant}-16` as FieldVariantName;

    const selectedOptions: DefaultChipType[] = useMemo(() => {
      const option = options.find((o) => o.value === field.value);
      return option ? [option] : [];
    }, [field, options]);

    const onSelectionChange = useCallback(
      ({ chips }: OnSelectionChangeArgs) => {
        helpers.setValue(chips[0]?.value || '');
        onChange?.();
      },
      [helpers, onChange],
    );

    const chipRenderer = useCallback<ChipRenderer<DefaultChipType>>(
      (chip) => {
        return (
          <div className={classNames('flex justify-between px-2.5', isChonky ? 'my-0.5 py-1' : 'my-0 py-0.5')}>
            <div className="truncate text-16 text-grey-11">{chip.label}</div>
          </div>
        );
      },
      [isChonky],
    );

    const listItemRenderer = useCallback<ListItemRenderer<DefaultChipType>>(
      (chip) => (
        <SelectChipOptionContainer>
          <div
            className={classNames('truncate text-grey-11', isChonky ? 'py-1.5 text-16' : 'py-1 text-14')}
            key={chip.value}
          >
            {chip.label}
          </div>
        </SelectChipOptionContainer>
      ),
      [isChonky],
    );

    return (
      <Box
        tx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minWidth: '256px',
          position: 'relative',
          ...spacingStyles,
        }}
        data-testid={dataTestId}
      >
        {isLabelHidden ? (
          <VisuallyHidden>
            <LabelPrimitive for={name} showAsterisk={required}>
              {label}
            </LabelPrimitive>
          </VisuallyHidden>
        ) : (
          <LabelPrimitive for={name} showAsterisk={required} tx={{ mb: 6, ...labelStyles }}>
            {label}
          </LabelPrimitive>
        )}

        <Select
          hasError={!!meta.touched && !!meta.error}
          chipRenderer={chipRenderer}
          listItemRenderer={listItemRenderer}
          closeMenuOnSelect={true}
          disabled={disabled}
          filterFunc={orderedSortedFilterFunc}
          isClearable={false}
          isLoading={isLoading}
          isSearchable={isSearchable}
          isSingleSelect
          onOptionsClose={onOptionsClose}
          onSelectionChange={onSelectionChange}
          options={options}
          placeholder={placeholder}
          renderAsInput
          selectedOptions={selectedOptions}
          showTriangle
          spacingStyles={{ width: '100%' }}
          variant={largeTextVariant}
          maxDropdownHeight={maxDropdownHeight}
          onOptionsOpen={onOptionsOpen}
        />
        <Error
          errorText={meta.error}
          isErrorVisible={hasError}
          id={errorID}
          tx={{ bottom: isChonky ? -22 : -18 }}
          data-testid={`${dataTestId}_error`}
        />
      </Box>
    );
  },
);

FormikSingleSelect.displayName = 'FormikSingleSelect';
