import { useField } from 'formik';

import { FormikInput } from '~/components/Zephyr/Formik/FormikInput';
import { EXPORT_IMAGES_SUFFIX_INPUT } from '~/constants/testIDs';

import { ExportImagesFormData } from '../../../ExportImagesModal';

export const PLACEHOLDER_IMAGE_BASE_NAME = 'IMAGE-O1';

interface ImageSuffixFormFieldProps {
  setIsEditing: (isEditing: boolean) => void;
}

export const ImageSuffixFormField = ({ setIsEditing }: ImageSuffixFormFieldProps) => {
  const [{ value: suffix }] = useField<ExportImagesFormData['suffix']>('suffix');
  const [{ value: format }] = useField<ExportImagesFormData['ext']>('ext');
  const [{ value: fileName }] = useField<ExportImagesFormData['fileName']>('fileName');

  return (
    <div className="flex flex-col" onFocus={() => setIsEditing(true)} onBlur={() => setIsEditing(false)}>
      <FormikInput
        type="text"
        data-testid={EXPORT_IMAGES_SUFFIX_INPUT}
        label="Suffix"
        required={false}
        name="suffix"
        spacingStyles={{
          minWidth: 259,
          margin: 0,
        }}
        variant="field-input-chonky"
      />
      <div className="mt-2 break-all text-12 text-grey-10">
        Preview:&nbsp;{fileName}
        {suffix}.{format}
      </div>
    </div>
  );
};
