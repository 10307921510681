import { ListSlackIntegrationsObject } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { Box, Button } from '@air/zephyr';
import { rgba } from 'polished';
import { memo } from 'react';
import { useTheme } from 'styled-components';

import { ConnectedSlackChannel } from './ConnectedSlackChannel';
import { DeleteBoardSlackIntegrationModal } from './DeleteBoardSlackIntegrationModal';

interface ConnectedToSlackProps {
  integration: ListSlackIntegrationsObject;
  boardId: string;
  canManageSlackIntegration?: boolean;
}
export const ConnectedToSlack = memo(({ integration, boardId, canManageSlackIntegration }: ConnectedToSlackProps) => {
  const theme = useTheme();
  const [showDeleteSlackIntegrationModal] = useAirModal(DeleteBoardSlackIntegrationModal);

  return (
    <Box
      tx={{
        position: 'relative',
        mt: 8,
        '.disconnect-button': {
          display: 'none',
        },
        ':hover': {
          '.disconnect-button': {
            display: 'block',
          },
        },
        '&:hover:not(.is-loading)': canManageSlackIntegration
          ? {
              '.connected-slack-channel-container': {
                backgroundColor: 'rgba(38, 38, 38, 0.1)', // pigeon800 @ 10%
                color: 'pigeon700',
              },
            }
          : undefined,
      }}
    >
      <ConnectedSlackChannel channelName={integration.channelName} />
      {canManageSlackIntegration && (
        <Button
          className="disconnect-button"
          variant={'button-unstyled'}
          onClick={() => showDeleteSlackIntegrationModal({ boardId, integration })}
          tx={{
            position: 'absolute',
            right: 10,
            top: '50%',
            transform: 'translateY(-50%)',
            height: 32,
            color: 'pigeon600',
            padding: '7px 10px',
            fontSize: 12,
            ':hover': {
              backgroundColor: rgba(theme.colors.pigeon800, 0.1),
            },
          }}
        >
          Disconnect
        </Button>
      )}
    </Box>
  );
});

ConnectedToSlack.displayName = 'ConnectedToSlack';
