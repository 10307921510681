import { ListSlackIntegrationsObject } from '@air/api/types';
import { TransactionModal } from '@air/zephyr';

import { CONFIRM_SLACK_INTEGRATION_DELETE_BUTTON } from '~/constants/testIDs';
import { useRemoveBoardSlackIntegration } from '~/swr-hooks/slack/useRemoveBoardSlackIntegration';

export interface DeleteSlackIntegrationModalProps extends AirModalProps {
  integration: ListSlackIntegrationsObject;
  boardId: string;
}

export const DeleteBoardSlackIntegrationModal = ({
  onClose,
  integration,
  boardId,
}: DeleteSlackIntegrationModalProps) => {
  const { removeBoardSlackIntegration } = useRemoveBoardSlackIntegration();

  const onClick = async () => {
    removeBoardSlackIntegration({
      integrationId: integration.integrationId,
      receiverId: integration.receiverId,
      boardId,
      channelName: integration.channelName,
    });
    onClose();
  };

  const modalDescription = (
    <p className="text-14 text-grey-11">
      Are you sure you want to disconnect this Slack integration? Any other integrations you’ve created will remain
      active.
    </p>
  );

  return (
    <TransactionModal
      onDismiss={onClose}
      modalLabel="Disconnect Slack channel?"
      modalDescription={modalDescription}
      primaryCTA={{
        children: `Disconnect`,
        onClick: onClick,
        variant: 'button-filled-destructive',
        'data-testid': CONFIRM_SLACK_INTEGRATION_DELETE_BUTTON,
      }}
      secondaryCTA={{ children: 'Cancel', onClick: onClose }}
    />
  );
};
