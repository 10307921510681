import { Box } from '@air/zephyr';
import { Tabs, TabsProps } from '@reach/tabs';
import { CSSProperties } from 'react';
import styled from 'styled-components';

export interface AirTabsContainerProps extends TabsProps {
  style?: CSSProperties;
}

export const AirTabsContainer = styled(Tabs)<AirTabsContainerProps>`
  [data-reach-tab-panels] {
    border-top: 1px solid var(--colors-grey5);
  }

  [data-reach-tab-list] {
    background-color: transparent;
  }

  [data-reach-tab] {
    color: var(--colors-grey10);
    font-size: 0.75em;
    border: 0;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    padding: 6px 10px;
    margin-right: 8px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.transparent};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  [data-reach-tab-panel][data-focus-visible-added] {
    outline: none;
  }

  [data-reach-tab][data-selected] {
    border-bottom-color: var(--colors-blue9);
    color: var(--colors-grey12);
  }
`;

export const AirTabsBoundedWrapper = styled(Box)`
  max-width: 594px;
  margin: 0 auto 120px;
`;
