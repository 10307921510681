import { EllipsisVertical } from '@air/next-icons';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { useToasts } from '@air/provider-toast';
import { Box, Button, IconButton, Text, TXProp } from '@air/zephyr';
import { RefObject } from 'react';

import { COPY_URL_BUTTON, COPY_URL_FIELD_TEXT } from '~/constants/testIDs';

import Spinner from '../Spinner';

export interface MenuAction {
  action: () => void;
  text: string;
}

export interface CopyFieldProps {
  copyButtonRef?: RefObject<HTMLButtonElement>;
  url: string | undefined;
  onCopy?: (url: string) => void;
  menuActions?: MenuAction[];
  tx?: TXProp & {
    Button?: TXProp;
    Text?: TXProp;
  };
  isDisabled?: boolean;
}

export const CopyField = ({ copyButtonRef, url, menuActions = [], onCopy, isDisabled, tx }: CopyFieldProps) => {
  const { showToast } = useToasts();
  const { Button: buttonTx, Text: textTx, ...restTX } = tx || {};

  const copyLink = async () => {
    if (!url || isDisabled) {
      return;
    }

    try {
      await window.navigator.clipboard.writeText(url);

      if (onCopy) {
        onCopy(url);
      }

      showToast('Link copied');
    } catch (_error) {
      showToast('Failed to copy link', { color: 'red' });
    }
  };

  return (
    <Box
      tx={{
        display: 'flex',
        height: 48,
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 4,
        border: '1px solid',
        borderColor: 'pigeon100',
        ...restTX,
      }}
    >
      {!url && !isDisabled && (
        <div className="ml-2">
          <Spinner size={20} />
        </div>
      )}
      <div className="ml-3 flex min-w-0 items-center">
        <Text
          as="span"
          tx={{
            color: 'pigeon600',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...textTx,
          }}
          data-testid={COPY_URL_FIELD_TEXT}
        >
          {url}
        </Text>
      </div>
      {/* Need to unset minWidth declared in Box component's BoxProps or share links affect button width */}
      <div className="flex items-center pr-2" style={{ minWidth: 'unset' }}>
        <Button
          tx={{ ml: 2, ...buttonTx }}
          onClick={copyLink}
          ref={copyButtonRef}
          variant="button-filled-blue"
          size="small"
          disabled={isDisabled}
          data-testid={isDisabled ? `${COPY_URL_BUTTON}-disabled` : COPY_URL_BUTTON}
        >
          Copy
        </Button>
        {menuActions.length > 0 && (
          <DropdownMenu
            trigger={
              <IconButton icon={EllipsisVertical} size="small" tx={{ ml: 8 }} variant="button-filled-grey">
                Open menu
              </IconButton>
            }
          >
            {renderDropdownItems({
              options: menuActions.map(({ text: label, action: onSelect }) => ({
                id: label,
                label,
                onSelect,
                type: 'item',
              })),
            })}
          </DropdownMenu>
        )}
      </div>
    </Box>
  );
};
